import Vue from 'vue'
import Vuex from 'vuex'
import Req from '../req/index'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        expandedKeys: [], //需要展开的节点id数据
        currentKey: null, //当前节点id
        from: "",
    },

    getters: {
        rebuildUrl: (state) =>{
            return (url) => {
                if(!state.from) {
                    return url;
                }
                const urlObj = new URL(url);
                urlObj.searchParams.set('from', state.from); // 添加 from=test 参数
                return urlObj.toString(); // 返回完整的 URL 字符串
            }
        } 
    },

    actions: {
        getWindowsVersion: function (store, [cb]) {
            let data = {
                from: store.state.from
            }
            Req.downloadPage.windowsVersion(data,cb);
        },
        getMacVersion: function (store, [cb]) {
            let data = {
                from: store.state.from
            }
            Req.downloadPage.macVersion(data,cb);
        },
        sendUserlog: function (store, [type, msg, cb]) {
            let data = {
                client: "iepose",
                type: type,
                msg: msg,
                href: document.location.href,
                referrer: document.referrer,
                useragent: navigator.userAgent
            }
            Req.user.log(data, cb);
        },
        sendLog: function (store, [ev_name,button_name,cb]) {
            let ctime = parseInt(new Date().getTime() / 1000);
            let events = [
                {
                    ctime: ctime,
                    ev_name:ev_name,
                    button_name:button_name,
                }
            ];
            let data = {
                cliType:"web-website-pc",
                events:events,
            }
            Req.user.sendUserLog(data, cb);
        },
    }

})