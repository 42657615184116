<template>
  <div class="downloadV4">
    <div class="download-item1">
      <div class="tip" v-if="tip.length > 0">
        <span class="tip-icon"></span> {{ tip[0] }}
      </div>
      <div class="t1-c">
        <div class="t1">极速 · 简单 · 好用的<span class="t2">远程访问软件</span></div>
      </div>
      <div class="btn-download">
        <div
          :class="{ 'btn-d': true, 'btn-d1': type == 6 }"
          @click="checkClick(6)"
        >
          <span class="btn-img btn-img1"></span>Nas
        </div>
        <div
          :class="{ 'btn-d': true, 'btn-d2': type == 7 }"
          @click="checkClick(7)"
        >
          <span class="btn-img btn-img2"></span>Windows
        </div>

        <div
          :class="{ 'btn-d': true, 'btn-d4': type == 3 }"
          @click="checkClick(3)"
        >
          <span class="btn-img btn-img4"></span>Android
        </div>

        <div
          :class="{ 'btn-d': true, 'btn-d6': type == 9 }"
          @click="checkClick(9)"
        >
          <span class="btn-img btn-img6"></span>Docker
        </div>

      </div>
    </div>
    <div class="download-item2">
      <div class="nas-item" v-show="type == 6">
        <div class="part-wrap">
          <div class="text1">NAS设备端</div>
          <div class="text2">点击对应的平台下载插件</div>
          <div
            :class="{ 'btn1 btna': true, 'btn1-active': parterner == 1 }"
            @click="parternerClick(1)"
          >
            <span class="btn1-left btn1-left1"></span>
            <span class="btn1-right">群晖</span>
          </div>
          <div
            :class="{ btn1: true, 'btn1-active': parterner == 2 }"
            @click="parternerClick(2)"
          >
            <span class="btn1-left btn1-left2"></span>
            <span class="btn1-right">铁威马</span>
          </div>
          <div
            :class="{ btn1: true, 'btn1-active': parterner == 3 }"
            @click="parternerClick(3)"
          >
            <span class="btn1-left btn1-left3"></span>
            <span class="btn1-right">威联通</span>
          </div>
          <div
            :class="{ btn1: true, 'btn1-active': parterner == 4 }"
            @click="parternerClick(4)"
          >
            <span class="btn1-left btn1-left4"></span>
            <span class="btn1-right">联想</span>
          </div>
        </div>
        <div class="download-wrap" v-show="parterner == 1">
          <div class="logo"></div>

          <a class="answer"
             @click="goDownload('https://www.iepose.com/helpcenter_v3/%E7%BE%A4%E6%99%96%E6%9E%B6%E6%9E%84%E6%9F%A5%E8%AF%A2.html','Nas-qh-设备架构查询')"
             href="javascript:;"
          >
            设备架构查询
          </a>
          <a class="getHelp"
             @click="goDownload('https://www.iepose.com/helpcenter_v3/%E7%BE%A4%E6%99%96-%E4%BD%BF%E7%94%A8%E8%AF%B4%E6%98%8E.html','Nas-qh-安装帮助文档')"
             href="javascript:;"
            >安装帮助文档</a
          >

          <div class="download-btn-wrap">
            <div
              class="download-btn"
              :class="{ 'download-btn-bg': downloadOver1 }"
              @mouseover="downloadOverLeave(1, true)"
              @mouseleave="downloadOverLeave(1, false)"
            >
              <div class="texta" :class="{ textb: downloadOver1 }">
                DSM6_x86
              </div>
              <div class="textc">&nbsp;</div>
              <a
                class="btn"
                v-if="downloadOver1 || true"
                @click="goDownload('https://tan.ionewu.com/api/jdxb/download/v2/qh_x86','Nas-DSM6_x86下载')"
                href="javascript:;"
                >下载</a
              >
            </div>
            <div
              class="download-btn"
              :class="{ 'download-btn-bg': downloadOver2 }"
              @mouseover="downloadOverLeave(2, true)"
              @mouseleave="downloadOverLeave(2, false)"
            >
              <div class="texta" :class="{ textb: downloadOver2 }">
                DSM6_arm
              </div>
              <div class="textc">&nbsp;</div>
              <a
                class="btn"
                v-if="downloadOver2 || true"
                @click="goDownload('https://tan.ionewu.com/api/jdxb/download/v2/qh_arm','Nas-DSM6_arm下载')"
                href="javascript:;"
                >下载</a
              >
            </div>
            <div
              class="download-btn"
              :class="{ 'download-btn-bg': downloadOver3 }"
              @mouseover="downloadOverLeave(3, true)"
              @mouseleave="downloadOverLeave(3, false)"
            >
              <div class="texta" :class="{ textb: downloadOver3 }">
                DSM7_x86
              </div>
              <div class="textc">&nbsp;</div>
              <a
                class="btn"
                v-if="downloadOver3 || true"
                @click="goDownload('https://tan.ionewu.com/api/jdxb/download/v2/qh_dsm7_x86','Nas-DSM7_x86下载')"
                href="javascript:;"
                >下载</a
              >
            </div>
            <div
              class="download-btn"
              :class="{ 'download-btn-bg': downloadOver4 }"
              @mouseover="downloadOverLeave(4, true)"
              @mouseleave="downloadOverLeave(4, false)"
            >
              <div class="texta" :class="{ textb: downloadOver4 }">
                DSM7_arm
              </div>
              <div class="textc">&nbsp;</div>
              <a
                class="btn"
                v-if="downloadOver4 || true"
                @click="goDownload('https://tan.ionewu.com/api/jdxb/download/v2/qh_dsm7_arm','Nas-DSM7_arm下载')"
                href="javascript:;"
                >下载</a
              >
            </div>
          </div>
        </div>
        <div class="download-wrap2" v-show="parterner == 2">
          <div class="logo"></div>
          <a class="answer"
             @click="goDownload('https://www.iepose.com/helpcenter_v3/%E9%93%81%E5%A8%81%E9%A9%AC%E6%9E%B6%E6%9E%84%E6%9F%A5%E8%AF%A2.html','Nas-twm-设备架构查询')"
             href="javascript:;"
            >设备架构查询</a
          >
          <a class="getHelp"
             @click="goDownload('https://www.iepose.com/helpcenter_v3/%E9%93%81%E5%A8%81%E9%A9%AC-%E4%BD%BF%E7%94%A8%E8%AF%B4%E6%98%8E.html','Nas-twm-安装帮助文档')"
             href="javascript:;"
            >安装帮助文档</a
          >
          <div class="download-btn-wrap">
            <div
              class="download-btn"
              :class="{ 'download-btn-bg': downloadOver1twm }"
              @mouseover="downloadOverLeaveTwm(1, true)"
              @mouseleave="downloadOverLeaveTwm(1, false)"
            >
              <div class="texta" :class="{ textb: downloadOver1twm }">
                TOS4_x86
              </div>
              <div class="textc">&nbsp;</div>
              <a
                class="btn"
                v-if="downloadOver1twm || true"
                @click="goDownload('https://tan.ionewu.com/api/jdxb/download/v2/twm','Nas-TOS4_x86下载')"
                href="javascript:;"
                >下载</a
              >
            </div>
            <div
              class="download-btn"
              :class="{ 'download-btn-bg': downloadOver3twm }"
              @mouseover="downloadOverLeaveTwm(3, true)"
              @mouseleave="downloadOverLeaveTwm(3, false)"
            >
              <div class="texta" :class="{ textb: downloadOver3twm }">
                TOS4_arm
              </div>
              <div class="textc">&nbsp;</div>
              <a
                class="btn"
                v-if="downloadOver3twm || true"
                @click="goDownload('https://tan.ionewu.com/api/jdxb/download/v2/twm_arm','Nas-TOS4_arm下载')"
                href="javascript:;"
                >下载</a
              >
            </div>
            <div
              class="download-btn"
              :class="{ 'download-btn-bg': downloadOver2twm }"
              @mouseover="downloadOverLeaveTwm(2, true)"
              @mouseleave="downloadOverLeaveTwm(2, false)"
            >
              <div class="texta" :class="{ textb: downloadOver2twm }">
                TOS5_x86
              </div>
              <div class="textc">&nbsp;</div>
              <a
                class="btn"
                v-if="downloadOver2twm || true"
                @click="goDownload('https://tan.ionewu.com/api/jdxb/download/v2/twm_tos5','Nas-TOS5_x86下载')"
                href="javascript:;"
                >下载</a
              >
            </div>
            <div
              class="download-btn"
              :class="{ 'download-btn-bg': downloadOver4twm }"
              @mouseover="downloadOverLeaveTwm(4, true)"
              @mouseleave="downloadOverLeaveTwm(4, false)"
            >
              <div class="texta" :class="{ textb: downloadOver4twm }">
                TOS5_arm
              </div>
              <div class="textc">&nbsp;</div>
              <a
                class="btn"
                v-if="downloadOver4twm || true"
                @click="goDownload('https://tan.ionewu.com/api/jdxb/download/v2/twm_tos5_arm','Nas-TOS5_arm下载')"
                href="javascript:;"
                >下载</a
              >
            </div>
          </div>
        </div>
        <div class="download-wrap4" v-show="parterner == 3">
          <div class="logo"></div>
          <div>
            <a class="answer"
               @click="goDownload('https://www.iepose.com/helpcenter_v3/%E5%A8%81%E8%81%94%E9%80%9A%E6%9E%B6%E6%9E%84%E6%9F%A5%E8%AF%A2.html','Nas-wlt-设备架构查询')"
               href="javascript:;"
              >设备架构查询</a
            >
            <a class="getHelp"
               @click="goDownload('https://www.iepose.com/helpcenter_v3/%E5%A8%81%E8%81%94%E9%80%9A-%E4%BD%BF%E7%94%A8%E8%AF%B4%E6%98%8E.html','Nas-wlt-安装帮助文档')"
               href="javascript:;"
              >安装帮助文档</a
            >
          </div>


          <div class="download-btn-wrap">
            <div class="texta">QTS_x86</div>
            <div class="textb">&nbsp;</div>
            <a
              class="btn"
              @click="goDownload('https://tan.ionewu.com/api/jdxb/download/v2/wlt','Nas-QTS_x86下载')"
              href="javascript:;"
              >下载</a
            >
          </div>
          <div class="download-btn-wrap">
            <div class="texta">QTS_arm</div>
            <div class="textb">&nbsp;</div>
            <a
              class="btn"
              @click="goDownload('https://tan.ionewu.com/api/jdxb/download/v2/wlt_arm','Nas-QTS_arm下载')"
              href="javascript:;"
              >下载</a
            >
          </div>
        </div>
        <div class="download-wrap5" v-show="parterner == 4">
          <div class="logo"></div>
          <a class="getHelp"
             @click="goDownload('https://www.iepose.com/helpcenter_v3/%E8%81%94%E6%83%B3%E4%B8%AA%E4%BA%BA%E4%BA%91-%E4%BD%BF%E7%94%A8%E8%AF%B4%E6%98%8E.html','Nas-lenovo-安装帮助文档')"
             href="javascript:;"
            >安装帮助文档</a
          >
          <div class="download-btn-wrap">
            <div class="texta">Lenovo-联想</div>
            <div class="textb">&nbsp;</div>
            <div class="btn" @click="goThirdParty()">请前往设备应用中心下载</div>
          </div>
        </div>
      </div>
      <div class="win-item" v-show="type == 7">
        <div class="t1">Windows客户端</div>
        <div class="t2">
          支持Win 8、Win 10、Win 11<br />
          暂不支持32位系统
        </div>
        <a
          class="download-btn"
          @click="goDownload('https://tan.ionewu.com/api/jdxb/download/v2/windows','Windows下载')"
          href="javascript:;"
          >立即下载</a
        >
        <div class="t3">版本：{{ windowsVersion }}</div>
        <div class="t3 t4">
          注：如果启动失败，请点击
          <a
            @click="goDownload('https://cdn.ionewu.com/upgrade/d/windows_devlibs.zip','Windows下载运行库')"
            href="javascript:;"
            >下载运行库</a
          >
        </div>
      </div>

      <div class="android-item" v-show="type == 3">
        <div class="t1">Android版 节点小宝相册备份</div>
        <div class="android-text">APP暂只支持相册备份到Windows客户端</div>
        <div class="qr"></div>

        <div class="t3">
          扫二维码或点击下方按钮下载节点小宝相册备份app
        </div>
        <a
          class="download-btn"
          @click="goDownload('https://tan.ionewu.com/api/jdxb/download/v2/android','Android下载')"
          href="javascript:;"
          >立即下载</a
        >
        <div class="t3">支持Android系统V6.0及以上版本应用</div>
      </div>

      <div class="docker-item" v-show="type == 9">
        <div class="t1">Docker</div>
        <div class="lr">
          <div class="left">
            <div class="t2">docker run(x86)</div>
            <div
              class="docker-copy"
              data-clipboard-text="docker run -d \
          --net host \
          --name owjdxb \
          -v $(pwd)/store:/data/store \
          ionewu/owjdxb"
              @click="copyClick"
            >
              复制
            </div>
            <div class="docker-copy-text">
              <div class="text1">docker run -d \</div>
              <div class="text2">--net host \</div>
              <div class="text3">--name owjdxb \</div>
              <div class="text4">
                -v <span style="color: #ff6c48">$</span>(pwd)/<span
                  style="color: #00c186"
                  >store:</span
                >/data/store \
              </div>
              <div class="text5">ionewu/owjdxb</div>
            </div>
            <div class="download-btns">
              <a
                class="download-btn download-btn1"
                @click="goDownload('https://tan.ionewu.com/api/jdxb/download/v2/docker_x86_tar','docker-x86下载tar')"
                href="javascript:;"
                >下载tar</a
              >
              <a
                class="download-btn download-btn2"
                @click="goDownload('https://tan.ionewu.com/api/jdxb/download/v2/docker_x86','docker-x86下载tgz')"
                href="javascript:;"
                >下载tgz</a
              >
            </div>
          </div>
          <div class="right">
            <div class="t2">docker run(arm_64)</div>
            <div
              class="docker-copy"
              data-clipboard-text="docker run -d \
          --net host \
          --name owjdxb \
          -v $(pwd)/store:/data/store \
          ionewu/owjdxb_a64"
              @click="copyClick"
            >
              复制
            </div>
            <div class="docker-copy-text">
              <div class="text1">docker run -d \</div>
              <div class="text2">--net host \</div>
              <div class="text3">--name owjdxb \</div>
              <div class="text4">
                -v <span style="color: #ff6c48">$</span>(pwd)/<span
                  style="color: #00c186"
                  >store:</span
                >/data/store \
              </div>
              <div class="text5">ionewu/owjdxb_a64</div>
            </div>
            <div class="download-btns">
              <a
                class="download-btn download-btn1"
                @click="goDownload('https://tan.ionewu.com/api/jdxb/download/v2/docker_arm_tar','docker-arm_64下载tar')"
                href="javascript:;"
                >下载tar</a
              >
              <a
                class="download-btn download-btn2"
                @click="goDownload('https://tan.ionewu.com/api/jdxb/download/v2/docker_arm','docker-arm_64下载tgz')"
                href="javascript:;"
                >下载tgz</a
              >
            </div>
          </div>
          <div class="right">
            <div class="t2">docker run(arm_32）</div>
            <div
              class="docker-copy"
              data-clipboard-text="docker run -d \
          --net host \
          --name owjdxb \
          -v $(pwd)/store:/data/store \
          ionewu/owjdxb_a32"
              @click="copyClick"
            >
              复制
            </div>
            <div class="docker-copy-text">
              <div class="text1">docker run -d \</div>
              <div class="text2">--net host \</div>
              <div class="text3">--name owjdxb \</div>
              <div class="text4">
                -v <span style="color: #ff6c48">$</span>(pwd)/<span
                  style="color: #00c186"
                  >store:</span
                >/data/store \
              </div>
              <div class="text5">ionewu/owjdxb_a32</div>
            </div>
            <div class="download-btns">
              <a
                class="download-btn download-btn1"
                @click="goDownload('https://tan.ionewu.com/api/jdxb/download/v2/docker_arm32_tar','docker-arm_32下载tar')"
                href="javascript:;"
                >下载tar</a
              >
              <a
                class="download-btn download-btn2"
                @click="goDownload('https://tan.ionewu.com/api/jdxb/download/v2/docker_arm32','docker-arm_32下载tgz')"
                href="javascript:;"
                >下载tgz</a
              >
            </div>
          </div>
        </div>

        <div class="t2-c">
          <div class="t2a">说明</div>
          <div class="t2a t2a1">
            net host ：以宿主机网络启动，可自动发现内网网段，自动组网
          </div>
          <div class="t2a t2a1">
            -v$(pwd)/store:data/store：用与节点小宝存储节点信息
          </div>
          <div class="t2a t2a1">
            容器内部端口为9118与宿主机端口冲突时可映射容器内部的9118端口
          </div>
          <div class="t2a t2a1">启动后访问&lt;IP&gt;:9118即可获取绑定码</div>
        </div>
      </div>

    </div>

    <div class="download-wrap" v-if="false">
      <div class="check-btns">
        <div
          :class="{
            'btn btn-left': true,
            'active-btn': type == 6 || type == 7 || type == 8,
            'unactive-left': type >= 1 && type <= 5,
          }"
          @click="checkClick(6)"
        >
          设备端
        </div>

        <div
          :class="{
            'btn btn-right': true,
            'active-btn': type >= 1 && type <= 5,
            'unactive-right': type == 6 || type == 7 || type == 8,
          }"
          @click="checkClick(1)"
        >
          客户端
        </div>
      </div>
      <div class="platform-wrap" v-show="type == 6 || type == 7 || type == 8">
        <div
          class="item item6"
          :class="{ 'active-item': type == 6 }"
          @click="checkClick(6)"
        >
          <div class="right-top">异地组网</div>
          <div class="item-icon item-iconf"></div>
          <div class="item-text">NAS</div>
          <div class="item-icon2" v-show="type == 6"></div>
        </div>
        <div
          class="item item1"
          :class="{ 'active-item': type == 7 }"
          @click="checkClick(7)"
        >
          <div class="right-top">异地组网</div>
          <div class="item-icon"></div>
          <div class="item-text">Windows</div>
          <div class="item-icon2" v-show="type == 7"></div>
        </div>
        <div
          class="item item2"
          :class="{ 'active-item': type == 8 }"
          @click="checkClick(8)"
        >
          <div class="right-top">异地组网</div>
          <div class="item-icon item-iconb"></div>
          <div class="item-text">MAC</div>
          <div class="item-icon2" v-show="type == 8"></div>
        </div>
      </div>
      <div class="platform-wrap" v-show="type >= 1 && type <= 5">
        <div
          class="item item1"
          :class="{ 'active-item': type == 1 }"
          @click="checkClick(1)"
        >
          <div class="right-top">异地组网</div>
          <div class="item-icon"></div>
          <div class="item-text">Windows</div>
          <div class="item-icon2" v-show="type == 1"></div>
        </div>
        <div
          class="item item2"
          :class="{ 'active-item': type == 2 }"
          @click="checkClick(2)"
        >
          <div class="right-top">异地组网</div>
          <div class="item-icon item-iconb"></div>
          <div class="item-text">MAC</div>
          <div class="item-icon2" v-show="type == 2"></div>
        </div>
        <div
          class="item item3"
          :class="{ 'active-item': type == 3 }"
          @click="checkClick(3)"
        >
          <div class="item-icon item-iconc"></div>
          <div class="item-text">Android</div>
          <div class="item-icon2" v-show="type == 3"></div>
        </div>
        <div
          class="item item4"
          :class="{ 'active-item': type == 4 }"
          @click="checkClick(4)"
        >
          <div class="item-icon item-icond"></div>
          <div class="item-text">IOS</div>
          <div class="item-icon2" v-show="type == 4"></div>
        </div>
        <div
          class="item item5"
          :class="{ 'active-item': type == 5 }"
          @click="checkClick(5)"
        >
          <div class="item-icon item-icone"></div>
          <div class="item-text">Web</div>
          <div class="item-icon2" v-show="type == 5"></div>
        </div>
      </div>
      <div class="platform1" v-show="type == 1">
        <div class="left">
          <div class="text1">Windows客户端</div>
          <div class="item-wrap">
            <div class="item">
              <div class="item-icon"></div>
              <div class="item-text">远程NAS</div>
            </div>
            <div class="item">
              <div class="item-icon item-icon2"></div>
              <div class="item-text">远程桌面</div>
            </div>
            <div class="item">
              <div class="item-icon item-icon3"></div>
              <div class="item-text">远程开机</div>
            </div>
          </div>
          <div class="item-wrap item-wrap2">
            <div class="item">
              <div class="item-icon item-icon4"></div>
              <div class="item-text">路由管理</div>
            </div>
            <div class="item">
              <div class="item-icon item-icon5"></div>
              <div class="item-text">小工具</div>
            </div>
            <div class="item">
              <div class="item-icon item-icon6"></div>
              <div class="item-text">高级服务</div>
            </div>
          </div>
          <a
            class="download-btn"
            href="https://tan.ionewu.com/api/jdxb/download/v2/windows"
            ><div class="download-icon"></div>
            下载</a
          >
          <!-- <div class="text2">v2.3.1</div> -->
        </div>
        <div class="right"></div>
      </div>
      <div class="platform1" v-show="type == 2">
        <div class="left">
          <div class="text1">Mac客户端</div>
          <div class="item-wrap">
            <div class="item">
              <div class="item-icon"></div>
              <div class="item-text">远程NAS</div>
            </div>
            <div class="item">
              <div class="item-icon item-icon2"></div>
              <div class="item-text">远程桌面</div>
            </div>
            <div class="item">
              <div class="item-icon item-icon3"></div>
              <div class="item-text">远程开机</div>
            </div>
          </div>
          <div class="item-wrap item-wrap2">
            <div class="item">
              <div class="item-icon item-icon4"></div>
              <div class="item-text">远程管理</div>
            </div>
            <div class="item">
              <div class="item-icon item-icon5"></div>
              <div class="item-text">小工具</div>
            </div>
            <div class="item">
              <div class="item-icon item-icon6"></div>
              <div class="item-text">高级服务</div>
            </div>
          </div>
          <a
            class="download-btn"
            target="_blank"
            href="https://tan.ionewu.com/api/jdxb/download/v2/mac"
            ><div class="download-icon"></div>
            下载</a
          >
          <!-- <div class="text2">v2.3.1</div> -->
        </div>
        <div class="right"></div>
      </div>
      <div class="platform1 platform3" v-show="type == 3">
        <div class="left">
          <div class="text1">Android客户端</div>
          <div class="item-wrap">
            <div class="item">
              <div class="item-icon"></div>
              <div class="item-text">远程NAS</div>
            </div>
            <div class="item">
              <div class="item-icon item-icon3"></div>
              <div class="item-text">远程开机</div>
            </div>
            <div class="item">
              <div class="item-icon item-icon4"></div>
              <div class="item-text">路由管理</div>
            </div>
          </div>
          <div class="item-wrap item-wrap2">
            <div class="item">
              <div class="item-icon item-icon5"></div>
              <div class="item-text">小工具</div>
            </div>
            <div class="item">
              <div class="item-icon item-icon6"></div>
              <div class="item-text">高级服务</div>
            </div>
          </div>
          <a
            class="download-btn"
            href="https://tan.ionewu.com/api/jdxb/download/v2/android"
            ><div class="download-icon"></div>
            下载</a
          >
          <!-- <div class="text2">v2.3.1</div> -->
        </div>
        <div class="right"></div>
      </div>
      <div class="platform1 platform3" v-show="type == 4">
        <div class="left">
          <div class="text1">iOS客户端</div>
          <div class="item-wrap">
            <div class="item">
              <div class="item-icon item-icon3"></div>
              <div class="item-text">远程开机</div>
            </div>
            <div class="item">
              <div class="item-icon item-icon4"></div>
              <div class="item-text">路由管理</div>
            </div>
            <div class="item">
              <div class="item-icon item-icon5"></div>
              <div class="item-text">小工具</div>
            </div>
          </div>
          <div class="item-wrap item-wrap2">
            <div class="item">
              <div class="item-icon item-icon6"></div>
              <div class="item-text">高级服务</div>
            </div>
          </div>
          <a
            class="download-btn"
            href="https://apps.apple.com/us/app/节点小宝/id1630528881"
            target="_blank"
            ><div class="download-icon"></div>
            下载</a
          >
          <!-- <div class="text2">v2.3.1</div> -->
        </div>
        <div class="right"></div>
      </div>
      <div class="platform1 platform5" v-show="type == 5">
        <div class="left">
          <div class="text1">Web客户端</div>
          <div class="item-wrap">
            <div class="item">
              <div class="item-icon item-icon3"></div>
              <div class="item-text">远程开机</div>
            </div>
            <div class="item">
              <div class="item-icon item-icon4"></div>
              <div class="item-text">路由管理</div>
            </div>
            <div class="item">
              <div class="item-icon item-icon5"></div>
              <div class="item-text">小工具</div>
            </div>
          </div>
          <div class="item-wrap item-wrap2">
            <div class="item">
              <div class="item-icon item-icon6"></div>
              <div class="item-text">高级服务</div>
            </div>
          </div>
          <a
            class="download-btn"
            href="https://yc.iepose.com/jdxb/home/node"
            target="_blank"
            ><div class="download-icon"></div>
            访问</a
          >
          <!-- <div class="text2">v2.3.1</div> -->
        </div>
        <div class="right"></div>
      </div>
      <div class="platform6" v-show="type == 6">
        <div class="part-wrap">
          <div class="text1">NAS设备端</div>
          <div class="text2">点击对应的平台下载插件</div>
          <div
            class="parterner"
            :class="{ 'parterner-bg': parterner == 1 }"
            @click="parternerClick(1)"
          >
            <div class="parterner-logo-wrap">
              <div class="parterner-logo1"></div>
            </div>
            <div class="parterner-text">群晖</div>
          </div>
          <div
            class="parterner"
            :class="{ 'parterner-bg': parterner == 2 }"
            @click="parternerClick(2)"
          >
            <div class="parterner-logo-wrap">
              <div class="parterner-logo2"></div>
            </div>
            <div class="parterner-text">铁威马</div>
          </div>
          <div
            class="parterner"
            :class="{ 'parterner-bg': parterner == 4 }"
            @click="parternerClick(4)"
          >
            <div class="parterner-logo-wrap">
              <div class="parterner-logo4"></div>
            </div>
            <div class="parterner-text">威联通</div>
          </div>
          <div
            class="parterner"
            :class="{ 'parterner-bg': parterner == 3 }"
            @click="parternerClick(3)"
          >
            <div class="parterner-logo-wrap">
              <div class="parterner-logo3"></div>
            </div>
            <div class="parterner-text">海康</div>
          </div>

          <div
            class="parterner"
            :class="{ 'parterner-bg': parterner == 5 }"
            @click="parternerClick(5)"
          >
            <div class="parterner-logo-wrap">
              <div class="parterner-logo5"></div>
            </div>
            <div class="parterner-text">联想</div>
          </div>
        </div>

        <div class="download-wrap" v-show="parterner == 1">
          <div class="logo"></div>

          <a class="answer" href="/support/6" target="_blank">设备架构查询</a>
          <a class="getHelp" href="./answer?type=10101" target="_blank"
            >安装帮助文档</a
          >

          <div class="download-btn-wrap">
            <div
              class="download-btn"
              :class="{ 'download-btn-bg': downloadOver1 }"
              @mouseover="downloadOverLeave(1, true)"
              @mouseleave="downloadOverLeave(1, false)"
            >
              <div class="texta" :class="{ textb: downloadOver1 }">
                DSM6_x86
              </div>
              <div class="textc">&nbsp;</div>
              <a
                class="btn"
                v-if="downloadOver1 || true"
                href="https://tan.ionewu.com/api/jdxb/download/v2/qh_x86"
                >下载</a
              >
            </div>
            <div
              class="download-btn"
              :class="{ 'download-btn-bg': downloadOver2 }"
              @mouseover="downloadOverLeave(2, true)"
              @mouseleave="downloadOverLeave(2, false)"
            >
              <div class="texta" :class="{ textb: downloadOver2 }">
                DSM6_arm
              </div>
              <div class="textc">&nbsp;</div>
              <a
                class="btn"
                v-if="downloadOver2 || true"
                href="https://tan.ionewu.com/api/jdxb/download/v2/qh_arm"
                >下载</a
              >
            </div>
            <div
              class="download-btn"
              :class="{ 'download-btn-bg': downloadOver3 }"
              @mouseover="downloadOverLeave(3, true)"
              @mouseleave="downloadOverLeave(3, false)"
            >
              <div class="texta" :class="{ textb: downloadOver3 }">
                DSM7_x86
              </div>
              <div class="textc">&nbsp;</div>
              <a
                class="btn"
                v-if="downloadOver3 || true"
                href="https://tan.ionewu.com/api/jdxb/download/v2/qh_dsm7_x86"
                >下载</a
              >
            </div>
            <div
              class="download-btn"
              :class="{ 'download-btn-bg': downloadOver4 }"
              @mouseover="downloadOverLeave(4, true)"
              @mouseleave="downloadOverLeave(4, false)"
            >
              <div class="texta" :class="{ textb: downloadOver4 }">
                DSM7_arm
              </div>
              <div class="textc">&nbsp;</div>
              <a
                class="btn"
                v-if="downloadOver4 || true"
                href="https://tan.ionewu.com/api/jdxb/download/v2/qh_dsm7_arm"
                >下载</a
              >
            </div>
          </div>
        </div>
        <div class="download-wrap2" v-show="parterner == 2">
          <div class="logo"></div>
          <a class="answer" href="/twm/device/query" target="_blank"
            >设备架构查询</a
          >
          <a class="getHelp" href="./answer?type=10102" target="_blank"
            >安装帮助文档</a
          >
          <div class="download-btn-wrap">
            <div
              class="download-btn"
              :class="{ 'download-btn-bg': downloadOver1twm }"
              @mouseover="downloadOverLeaveTwm(1, true)"
              @mouseleave="downloadOverLeaveTwm(1, false)"
            >
              <div class="texta" :class="{ textb: downloadOver1twm }">
                TOS4_x86
              </div>
              <div class="textc">&nbsp;</div>
              <a
                class="btn"
                v-if="downloadOver1twm || true"
                href="https://tan.ionewu.com/api/jdxb/download/v2/twm"
                >下载</a
              >
            </div>
            <div
              class="download-btn"
              :class="{ 'download-btn-bg': downloadOver3twm }"
              @mouseover="downloadOverLeaveTwm(3, true)"
              @mouseleave="downloadOverLeaveTwm(3, false)"
            >
              <div class="texta" :class="{ textb: downloadOver3twm }">
                TOS4_arm
              </div>
              <div class="textc">&nbsp;</div>
              <a
                class="btn"
                v-if="downloadOver3twm || true"
                href="https://tan.ionewu.com/api/jdxb/download/v2/twm_arm"
                >下载</a
              >
            </div>
            <div
              class="download-btn"
              :class="{ 'download-btn-bg': downloadOver2twm }"
              @mouseover="downloadOverLeaveTwm(2, true)"
              @mouseleave="downloadOverLeaveTwm(2, false)"
            >
              <div class="texta" :class="{ textb: downloadOver2twm }">
                TOS5_x86
              </div>
              <div class="textc">&nbsp;</div>
              <a
                class="btn"
                v-if="downloadOver2twm || true"
                href="https://tan.ionewu.com/api/jdxb/download/v2/twm_tos5"
                >下载</a
              >
            </div>
            <div
              class="download-btn"
              :class="{ 'download-btn-bg': downloadOver4twm }"
              @mouseover="downloadOverLeaveTwm(4, true)"
              @mouseleave="downloadOverLeaveTwm(4, false)"
            >
              <div class="texta" :class="{ textb: downloadOver4twm }">
                TOS5_arm
              </div>
              <div class="textc">&nbsp;</div>
              <a
                class="btn"
                v-if="downloadOver4twm || true"
                href="https://tan.ionewu.com/api/jdxb/download/v2/twm_tos5_arm"
                >下载</a
              >
            </div>
          </div>
        </div>
        <div class="download-wrap3" v-show="parterner == 3">
          <div class="logo"></div>

          <a class="getHelp" href="./answer?type=10105" target="_blank"
            >安装帮助文档</a
          >
          <div class="download-btn-wrap">
            <div class="texta">HIKVISION-海康</div>
            <div class="textb">&nbsp;</div>
            <div class="btn">请前往设备应用中心下载</div>
          </div>
        </div>
        <div class="download-wrap4" v-show="parterner == 4">
          <div class="logo"></div>
          <div>
            <a class="answer" href="/wlt/device/query" target="_blank"
              >设备架构查询</a
            >
            <a class="getHelp" href="./answer?type=10103" target="_blank"
              >安装帮助文档</a
            >
          </div>

          <div class="download-btn-wrap" v-if="false">
            <div class="texta">QTS-威联通</div>
            <div class="textb">&nbsp;</div>
            <a
              class="btn"
              href="https://tan.ionewu.com/api/jdxb/download/v2/wlt"
              >下载</a
            >
          </div>

          <div class="download-btn-wrap">
            <div class="texta">QTS_x86</div>
            <div class="textb">&nbsp;</div>
            <a
              class="btn"
              href="https://tan.ionewu.com/api/jdxb/download/v2/wlt"
              >下载</a
            >
          </div>
          <div class="download-btn-wrap">
            <div class="texta">QTS_arm</div>
            <div class="textb">&nbsp;</div>
            <a
              class="btn"
              href="https://tan.ionewu.com/api/jdxb/download/v2/wlt_arm"
              >下载</a
            >
          </div>
        </div>
        <div class="download-wrap5" v-show="parterner == 5">
          <div class="logo"></div>
          <a class="getHelp" href="./answer?type=10104" target="_blank"
            >安装帮助文档</a
          >
          <div class="download-btn-wrap">
            <div class="texta">Lenovo-联想</div>
            <div class="textb">&nbsp;</div>
            <div class="btn">请前往设备应用中心下载</div>
          </div>
        </div>
      </div>
      <div class="platform1 platform7" v-show="type == 7">
        <div class="left">
          <div class="text1">Windows设备端</div>
          <div class="item-wrap">
            <div class="item">
              <div class="item-icon item-icon7"></div>
              <div class="item-text">远控本机</div>
            </div>
            <div class="item">
              <div class="item-icon item-icon8"></div>
              <div class="item-text">相册备份</div>
            </div>
            <div class="item">
              <div class="item-icon item-icon9"></div>
              <div class="item-text">发起唤醒</div>
            </div>
            <div class="item">
              <div class="item-icon item-icon10"></div>
              <div class="item-text">端口映射</div>
            </div>
          </div>
          <a
            class="download-btn"
            href="https://tan.ionewu.com/api/jdxb/download/v2/windows_server"
            ><div class="download-icon"></div>
            下载</a
          >
        </div>
        <div class="right"></div>
      </div>
      <div class="platform1 platform7" v-show="type == 8">
        <div class="left">
          <div class="text1">Mac设备端</div>
          <div class="item-wrap">
            <div class="item">
              <div class="item-icon item-icon7"></div>
              <div class="item-text">远控本机</div>
            </div>
            <div class="item">
              <div class="item-icon item-icon8"></div>
              <div class="item-text">相册备份</div>
            </div>
            <div class="item">
              <div class="item-icon item-icon9"></div>
              <div class="item-text">发起唤醒</div>
            </div>
            <div class="item">
              <div class="item-icon item-icon10"></div>
              <div class="item-text">端口映射</div>
            </div>
          </div>
          <a
            class="download-btn"
            target="_blank"
            href="https://tan.ionewu.com/api/jdxb/download/v2/mac"
            ><div class="download-icon"></div>
            下载</a
          >
        </div>
        <div class="right right8"></div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Foot from "@/components/Foot.vue";
import Clipboard from "clipboard";
import { mapActions } from "vuex";

export default {
  name: "DownloadV4",
  data: function () {
    return {
      windowsVersion: "2.9.9",
      macVersion: "2.9.8",
      type: 6,
      parterner: 1,
      downloadOver1: false,
      downloadOver2: false,
      downloadOver3: false,
      downloadOver4: false,

      downloadOver1twm: false,
      downloadOver2twm: false,
      downloadOver3twm: false,
      downloadOver4twm: false,
      tip: [],
    };
  },
  components: {
    Foot,
  },
  methods: {
    ...mapActions(["getWindowsVersion", "getMacVersion","sendLog"]),
    goThirdParty:function(){
      this.sendLog(["PC_product_clicked","Nas-Lenovo前往设备应用中心下载"]);
    },
    goDownload:function(url,type){
      window.open(this.$store.getters.rebuildUrl(url));
      this.sendLog(["PC_product_clicked",type]);
    },
    copyClick: function () {
      let _that = this;
      let clipboard = new Clipboard(".docker-copy");
      clipboard.on("success", (e) => {
        clipboard.destroy();
        _that.tip.push("复制成功");
        setTimeout(function () {
          _that.tip = [];
        }, 1500);
      });
      clipboard.on("error", (e) => {
        clipboard.destroy();
        _that.tip.push("复制失败");
        setTimeout(function () {
          _that.tip = [];
        }, 1500);
      });
    },
    goClick: function (data) {
      let routeUrl = this.$router.resolve({
        path: data, // 这里的路径就可以正常的写，不需要添加_blank: true
      });
      window.open(routeUrl.href, "_blank");
    },

    checkClick: function (data) {
      this.type = data;
    },

    parternerClick: function (data) {
      let _that = this;
      _that.parterner = data;
    },
    downloadOverLeave: function (data, data2) {
      let _that = this;
      if (data == 1) {
        _that.downloadOver1 = data2;
      } else if (data == 2) {
        _that.downloadOver2 = data2;
      } else if (data == 3) {
        _that.downloadOver3 = data2;
      } else {
        _that.downloadOver4 = data2;
      }
    },
    downloadOverLeaveTwm: function (data, data2) {
      let _that = this;
      if (data == 1) {
        _that.downloadOver1twm = data2;
      } else if (data == 2) {
        _that.downloadOver2twm = data2;
      } else if (data == 3) {
        _that.downloadOver3twm = data2;
      } else {
        _that.downloadOver4twm = data2;
      }
    },
  },
  mounted() {
    let _that = this;
    // const isWindows = navigator.platform.includes("Win");
    // const isMacOS = navigator.platform.includes("Mac");
    // if (isWindows) {
    //   _that.type = 1;
    // } else if (isMacOS) {
    //   _that.type = 2;
    // } else {
    //   _that.type = 1;
    // }

    _that.type = 6;

    if (_that.$route.query.client) {
      let c = _that.$route.query.client;
      if (c == "windows") {
        _that.type = 1;
      } else if (c == "mac") {
        _that.type = 2;
      } else if (c == "Android") {
        _that.type = 3;
      } else if (c == "ios") {
        _that.type = 4;
      }
    }

    if (_that.$route.query.device) {
      let d = _that.$route.query.device;
      if (d == "nas") {
        _that.type = 6;
      } else if (d == "windows") {
        _that.type = 7;
      } else if (d == "mac") {
        _that.type = 8;
      } else if (d == "docker") {
        _that.type = 9;
      } else if (d == "openwrt") {
        _that.type = 10;
      }
    }

    if (_that.$route.query.brand) {
      let b = _that.$route.query.brand;
      if (b == "sylongy") {
        _that.parterner = 1;
      } else if (b == "terramaster") {
        _that.parterner = 2;
      } else if (b == "hikvision") {
        _that.parterner = 3;
      } else if (b == "qnap") {
        _that.parterner = 4;
      } else if (b == "lenovo") {
        _that.parterner = 5;
      }
    }
    _that.getWindowsVersion([
      function (err, data) {
        if (data.rtn == 0) {
          _that.windowsVersion = data.data.version;
        }
      },
    ]);
    _that.getMacVersion([
      function (err, data) {
        if (data.rtn == 0) {
          _that.macVersion = data.data.version;
        }
      },
    ]);
  },
};
</script>

<style lang="less" scoped>
.mixinBg {
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.downloadV4 {
  .download-item1 {
    position: relative;
    width: 100%;
    height: 300px;
    background-image: url("../assets/v4/download1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
    .tip {
      position: absolute;
      left: 50%;
      top: 28px;
      transform: translate(-50%, 0);
      width: 112px;
      height: 40px;
      background: #ffffff;
      box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.1);
      border: 0.5px solid #dcdcdc;
      border-radius: 6px;
      line-height: 40px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      text-align: center;

      .tip-icon {
        display: inline-block;
        margin-top: 8px;
        margin-right: 8px;
        width: 20px;
        height: 20px;
        background-image: url("../assets/v4/download17.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        vertical-align: top;
      }
    }

    .t1-c {
      margin-top: 68px;
      text-align: center;
      .t1 {
        display: inline-block;
        color: #17171b;
        font-size: 48px;
        font-weight: bold;
        line-height: 80px;
        .t2{
          margin-left: 5px;
          background: linear-gradient(to right, #3772FF,#825AFF,#FF741D);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    .btn-download {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: 1280px;
      height: 90px;
      border-radius: 20px 20px 0px 0px;
      font-size: 18px;
      text-align: center;
      .btn-d {
        display: inline-block;
        margin-right: 14px;
        text-align: center;
        width: 200px;
        height: 90px;
        line-height: 90px;
        cursor: pointer;
        color: #17171b;
        border-radius: 20px 20px 0px 0px;

        .btn-img {
          display: inline-block;
          margin-top: 23px;
          margin-right: 6px;
          background-image: url("../assets/v4/home3.svg");
          width: 44px;
          height: 44px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          vertical-align: top;
        }
        .btn-img2 {
          background-image: url("../assets/v4/home4.svg");
        }
        .btn-img3 {
          background-image: url("../assets/v4/home45.svg");
        }
        .btn-img4 {
          background-image: url("../assets/v4/home6.svg");
        }
        .btn-img5 {
          background-image: url("../assets/v4/home7.svg");
        }
        .btn-img6 {
          background-image: url("../assets/v4/home8.svg");
        }
        .btn-img7 {
          background-image: url("../assets/v4/home9.svg");
        }
      }
      .btn-d1,
      .btn-d2,
      .btn-d3,
      .btn-d4,
      .btn-d5,
      .btn-d6,
      .btn-d7 {
        color: #3772FF;
        background-color: #ffffff;
        .btn-img1 {
          background-image: url("../assets/v4/home46.svg");
        }
        .btn-img2 {
          background-image: url("../assets/v4/home47.svg");
        }
        .btn-img3 {
          background-image: url("../assets/v4/home5.svg");
        }
        .btn-img4 {
          background-image: url("../assets/v4/home48.svg");
        }
        .btn-img5 {
          background-image: url("../assets/v4/home49.svg");
        }
        .btn-img6 {
          background-image: url("../assets/v4/home50.svg");
        }
        .btn-img7 {
          background-image: url("../assets/v4/home51.svg");
        }
      }

      .btn-d:nth-child(7) {
        margin-right: 0px;
      }
    }
  }
  .download-item2 {
    padding-top: 1px;
    .nas-item {
      margin: 0 auto;
      margin-top: 70px;
      margin-bottom: 120px;
      width: 1280px;
      height: 560px;
      background: #f6f8ff;
      border: 1px solid #d9e1ff;
      border-radius: 16px;

      .part-wrap {
        display: inline-block;
        margin-top: 36px;
        vertical-align: top;
        .text1 {
          margin-left: 50px;
          font-size: 30px;
          font-weight: bold;
          color: #1d2129;
        }
        .text2 {
          margin-left: 50px;
          margin-top: 10px;
          color: #4e5969;
          font-size: 18px;
        }
        .btn1 {
          margin-left: 30px;
          width: 300px;
          height: 90px;
          background-image: url("../assets/v4/download3.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          color: #57576c;
          cursor: pointer;

          .btn1-left {
            float: left;
            margin-left: 34px;
            margin-top: 10px;
            width: 110px;
            height: 60px;
            background-image: url("../assets/v4/download5.svg");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
          .btn1-left2 {
            background-image: url("../assets/v4/download7.svg");
          }
          .btn1-left3 {
            background-image: url("../assets/v4/download10.svg");
          }
          .btn1-left4 {
            width: 130px;
            background-image: url("../assets/v4/download13.svg");
          }
          .btn1-right {
            float: right;
            width: 120px;
            font-size: 16px;
            line-height: 86px;
          }
        }
        .btn1-active {
          color: #ffffff;
          background-image: url("../assets/v4/download4.jpg");

          .btn1-left1 {
            background-image: url("../assets/v4/download6.svg");
          }
          .btn1-left2 {
            background-image: url("../assets/v4/download8.svg");
          }
          .btn1-left3 {
            background-image: url("../assets/v4/download9.png");
          }
          .btn1-left4 {
            background-image: url("../assets/v4/download12.svg");
          }
        }
        .btna {
          margin-top: 10px;
        }
      }
      .download-wrap {
        display: inline-block;
        margin-left: 90px;
        margin-top: 45px;
        vertical-align: top;
        width: 818px;
        height: 460px;
        background-image: url("../assets/download110.png");
        .mixinBg();
        box-shadow: 0 4px 8px 0 rgba(10, 24, 61, 32%);
        border-radius: 16px;
        text-align: center;

        .logo {
          margin: 0 auto;
          margin-top: 64px;
          width: 212px;
          height: 55px;
          background-image: url("../assets/download111.svg");
          .mixinBg();
        }

        .download-btn-wrap {
          padding-top: 24px;
          margin: 0 auto;
          margin-top: 60px;
          width: 730px;
          height: 174px;
          border-radius: 16px;
          background-color: rgba(4, 4, 22, 0.8);
          text-align: center;
          box-sizing: border-box;

          .download-btn {
            position: relative;
            display: inline-block;
            padding-top: 1px;
            vertical-align: top;
            width: 173px;
            height: 128px;
            border-radius: 16px;
            box-sizing: border-box;
            cursor: pointer;

            .texta {
              margin-top: 35px;
              margin-top: 10px;
              color: #ffffff;
              font-size: 20px;
              text-align: center;
              font-weight: 700;
            }
            .textb {
              margin-top: 10px;
            }
            .textc {
              margin-top: 8px;
              font-size: 16px;
              color: #ffffff;
              text-align: center;
            }
            .btn {
              position: relative;
              display: block;
              margin: 0 auto;
              margin-top: 12px;
              padding-left: 71px;
              text-align: left;
              width: 146px;
              height: 36px;
              line-height: 36px;
              border-radius: 4px;
              background-color: #3772FF;
              font-size: 16px;
              font-weight: 700;
              color: #ffffff;
              box-sizing: border-box;
              text-decoration: none;
            }
            .btn::before {
              position: absolute;
              left: 43px;
              top: 7px;
              content: "";
              width: 20px;
              height: 20px;
              background-image: url("../assets/download112.svg");
              .mixinBg();
            }
          }
          .download-btn:nth-child(-n + 3)::after {
            position: absolute;
            right: 0;
            top: 16px;
            content: "";
            height: 78px;
            width: 1px;
            background-image: url("../assets/download114.png");
            .mixinBg();
          }
          .download-btn-bg {
            background-image: url("../assets/download113.png");
            .mixinBg();
          }
        }

        .answer {
          position: relative;
          margin-top: 20px;
          margin-right: 16px;
          display: inline-block;
          text-decoration: none;
          color: #3772FF;
          font-size: 16px;
          cursor: pointer;
          font-weight: 700;
          width: 116px;
          height: 28px;
          line-height: 30px;
          background-color: rgba(255, 255, 255, 0.9);
          border-radius: 4px;
        }
        .getHelp {
          display: inline-block;
          margin-top: 20px;
          line-height: 28px;
          font-size: 14px;
          color: #ffffff;
          text-decoration: none;
        }
      }
      .download-wrap2 {
        display: inline-block;
        margin-left: 90px;
        margin-top: 45px;
        vertical-align: top;
        width: 818px;
        height: 460px;
        background-image: url("../assets/download115.png");
        .mixinBg();
        box-shadow: 0 4px 8px 0 rgba(10, 24, 61, 32%);
        border-radius: 16px;
        text-align: center;

        .logo {
          margin: 0 auto;
          margin-top: 60px;
          width: 192px;
          height: 93px;
          background-image: url("../assets/download116.svg");
          .mixinBg();
        }

        .answer {
          position: relative;
          margin-top: 20px;
          margin-right: 16px;
          display: inline-block;
          text-decoration: none;
          color: #3772FF;
          font-size: 16px;
          cursor: pointer;
          font-weight: 700;
          width: 116px;
          height: 28px;
          line-height: 30px;
          background-color: rgba(255, 255, 255, 0.9);
          border-radius: 4px;
        }
        .getHelp {
          display: inline-block;
          margin-top: 20px;
          height: 28px;
          font-size: 14px;
          color: #ffffff;
          text-decoration: none;
        }

        .download-btn-wrap {
          padding-top: 24px;
          margin: 0 auto;
          margin-top: 40px;
          width: 730px;
          height: 174px;
          border-radius: 16px;
          background-color: rgba(4, 4, 22, 0.8);
          text-align: center;
          box-sizing: border-box;

          .download-btn {
            position: relative;
            display: inline-block;
            padding-top: 1px;
            vertical-align: top;
            width: 173px;
            height: 128px;
            border-radius: 16px;
            box-sizing: border-box;
            cursor: pointer;

            .texta {
              margin-top: 35px;
              margin-top: 10px;
              color: #ffffff;
              font-size: 20px;
              text-align: center;
              font-weight: 700;
            }
            .textb {
              margin-top: 10px;
            }
            .textc {
              margin-top: 8px;
              font-size: 16px;
              color: #ffffff;
              text-align: center;
            }
            .btn {
              position: relative;
              display: block;
              margin: 0 auto;
              margin-top: 12px;
              padding-left: 71px;
              text-align: left;
              width: 146px;
              height: 36px;
              line-height: 36px;
              border-radius: 4px;
              background-color: #3772FF;
              font-size: 16px;
              font-weight: 700;
              color: #ffffff;
              box-sizing: border-box;
              text-decoration: none;
            }
            .btn::before {
              position: absolute;
              left: 43px;
              top: 7px;
              content: "";
              width: 20px;
              height: 20px;
              background-image: url("../assets/download112.svg");
              .mixinBg();
            }
          }
          .download-btn:nth-child(-n + 3)::after {
            position: absolute;
            right: 0;
            top: 16px;
            content: "";
            height: 78px;
            width: 1px;
            background-image: url("../assets/download114.png");
            .mixinBg();
          }
          .download-btn-bg {
            background-image: url("../assets/download113.png");
            .mixinBg();
          }
        }
      }
      .download-wrap4 {
        display: inline-block;
        margin-left: 90px;
        margin-top: 45px;
        vertical-align: top;
        width: 818px;
        height: 460px;
        background-image: url("../assets/download118.png");
        .mixinBg();
        box-shadow: 0 4px 8px 0 rgba(10, 24, 61, 32%);
        border-radius: 16px;
        text-align: center;

        .logo {
          margin: 0 auto;
          margin-top: 90px;
          width: 231px;
          height: 37px;
          background-image: url("../assets/download119.svg");
          .mixinBg();
        }

        .answer {
          position: relative;
          margin-top: 20px;
          margin-right: 16px;
          display: inline-block;
          text-decoration: none;
          color: #3772FF;
          font-size: 16px;
          cursor: pointer;
          font-weight: 700;
          width: 116px;
          height: 28px;
          line-height: 30px;
          background-color: rgba(255, 255, 255, 0.9);
          border-radius: 4px;
        }
        .getHelp {
          display: inline-block;
          margin-top: 20px;
          font-size: 14px;
          color: #ffffff;
          line-height: 28px;
          text-decoration: none;
        }

        .download-btn-wrap {
          display: inline-block;
          padding-top: 42px;
          margin: 0 auto;
          margin-top: 40px;
          width: 302px;
          height: 204px;
          border-radius: 2px;
          background-image: url("../assets/download113.png");
          .mixinBg();
          box-sizing: border-box;

          .texta {
            font-size: 20px;
            color: #ffffff;
            font-weight: 700;
            text-align: center;
          }
          .textb {
            margin-top: 8px;
            font-size: 16px;
            color: #ffffff;
            text-align: center;
          }
          .btn {
            text-align: left;
            position: relative;
            display: block;
            padding-left: 103px;
            margin: 0 auto;
            margin-top: 35px;
            width: 209px;
            height: 42px;
            line-height: 42px;
            background-color: #3772FF;
            border-radius: 4px;
            color: #ffffff;
            box-sizing: border-box;
            font-size: 16px;
            font-weight: 700;
            cursor: pointer;
            text-decoration: none;
          }
          .btn::before {
            position: absolute;
            left: 75px;
            top: 11px;
            content: "";
            width: 20px;
            height: 20px;
            background-image: url("../assets/download112.svg");
            .mixinBg();
          }
          .btn:hover {
            cursor: pointer;
            opacity: 0.9;
          }
        }
        /*	.download-btn-wrap {
					padding-top: 42px;
					margin: 0 auto;
					margin-top: 58px;
					width: 402px;
					height: 204px;
					border-radius: 2px;
					background-image: url("../assets/download113.png");
					.mixinBg();
					box-sizing: border-box;

					.texta {
						font-size: 20px;
						color: #ffffff;
						font-weight: 700;
						text-align: center;
					}
					.textb {
						margin-top: 8px;
						font-size: 16px;
						color: #ffffff;
						text-align: center;
					}
					.btn {
						position: relative;
						display: block;
						padding-left: 103px;
						margin: 0 auto;
						margin-top: 35px;
						width: 209px;
						height: 42px;
						line-height: 42px;
						background-color: #2749c1;
						border-radius: 4px;
						color: #ffffff;
						box-sizing: border-box;
						font-size: 16px;
						font-weight: 700;
						cursor: pointer;
						text-decoration: none;
					}
					.btn::before {
						position: absolute;
						left: 75px;
						top: 11px;
						content: "";
						width: 20px;
						height: 20px;
						background-image: url("../assets/download112.svg");
						.mixinBg();
					}
					.btn:hover {
						cursor: pointer;
						opacity: 0.9;
					}
				}*/
      }
      .download-wrap5 {
        display: inline-block;
        margin-left: 90px;
        margin-top: 45px;
        vertical-align: top;
        width: 818px;
        height: 460px;
        background-image: url("../assets/download120.png");
        .mixinBg();
        box-shadow: 0 4px 8px 0 rgba(10, 24, 61, 32%);
        border-radius: 16px;

        .logo {
          margin: 0 auto;
          margin-top: 90px;
          width: 221px;
          height: 44px;
          background-image: url("../assets/download121.svg");
          .mixinBg();
        }
        .getHelp {
          margin: 0 auto;
          display: block;
          width: 200px;
          line-height: 30px;
          margin-top: 15px;
          font-size: 14px;
          color: #ffffff;
          text-decoration: none;
          text-align: center;
        }

        .download-btn-wrap {
          padding-top: 42px;
          margin: 0 auto;
          margin-top: 35px;
          width: 402px;
          height: 204px;
          border-radius: 2px;
          background-image: url("../assets/download113.png");
          .mixinBg();
          box-sizing: border-box;

          .texta {
            font-size: 20px;
            color: #ffffff;
            font-weight: 700;
            text-align: center;
          }
          .textb {
            margin-top: 8px;
            font-size: 16px;
            color: #ffffff;
            text-align: center;
          }
          .btn {
            position: relative;
            margin: 0 auto;
            margin-top: 35px;
            width: 209px;
            height: 42px;
            line-height: 42px;
            background-color: #3772FF;
            border-radius: 4px;
            color: #ffffff;
            box-sizing: border-box;
            font-size: 16px;
            font-weight: 700;
            cursor: pointer;
            text-align: center;
          }
          .btn:hover {
            cursor: pointer;
            opacity: 0.9;
          }
        }
      }
    }
    .win-item,
    .macos-item {
      margin: 0 auto;
      margin-top: 70px;
      margin-bottom: 120px;
      width: 700px;
      height: 300px;
      background: #f6f8ff;
      border: 1px solid #d9e1ff;
      border-radius: 16px;
      text-align: center;

      .t1 {
        margin-top: 46px;
        font-size: 36px;
        font-weight: bold;
        color: #17171b;
      }
      .t2 {
        margin-top: 10px;
        color: #4e5969;
        font-size: 16px;
        line-height: 30px;
      }
      .download-btn {
        display: inline-block;
        margin: 0 auto;
        margin-top: 30px;
        text-decoration: none;
        color: #ffffff;
        font-size: 16px;
        line-height: 44px;
        width: 300px;
        height: 44px;
        background: #3772FF;
        border-radius: 6px;
        text-align: center;
        cursor: pointer;
      }
      .download-btn:hover {
        opacity: 0.9;
      }
      .t3 {
        margin-top: 15px;
        color: #57576c;
        font-size: 14px;
      }
      .t4 {
        a {
          color: #3772FF;
          text-decoration: none;
        }
        a:hover {
          text-decoration: underline;
        }
      }
    }
    .win-item {
      height: 350px;
    }
    .android-item,
    .ios-item {
      margin: 0 auto;
      margin-top: 70px;
      margin-bottom: 120px;
      width: 700px;
      height: 500px;
      background: #f6f8ff;
      border: 1px solid #d9e1ff;
      border-radius: 16px;
      text-align: center;
      .t1 {
        margin-top: 46px;
        font-size: 36px;
        font-weight: bold;
        color: #17171b;
      }
      .android-text{
        margin-top: 8px;
        font-size: 16px;
        line-height: 23px;
        color: #FF6838;
      }
      .qr {
        margin: 0 auto;
        margin-top: 16px;
        width: 160px;
        height: 160px;
        background-image: url("../assets/v4/download14.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .qr-ios {
        background-image: url("../assets/v4/download15.png");
      }
      .download-btn {
        display: inline-block;
        margin: 0 auto;
        margin-top: 30px;
        text-decoration: none;
        color: #ffffff;
        font-size: 16px;
        line-height: 44px;
        width: 300px;
        height: 44px;
        background: #3772FF;
        border-radius: 6px;
        text-align: center;
        cursor: pointer;
      }
      .download-btn:hover {
        opacity: 0.9;
      }
      .t3 {
        margin-top: 15px;
        color: #57576c;
        font-size: 14px;
      }
    }
    .docker-item {
      margin: 0 auto;
      margin-top: 70px;
      margin-bottom: 120px;
      width: 1280px;
      height: 700px;
      background: #f6f8ff;
      border: 1px solid #d9e1ff;
      border-radius: 16px;

      .t1 {
        margin-top: 46px;
        font-size: 36px;
        font-weight: bold;
        color: #17171b;
        text-align: center;
      }

      .left,
      .right {
        margin-top: 42px;
        float: left;
        margin-left: 40px;
        width: 380px;

        .t2 {
          text-align: center;
          font-size: 24px;
          font-weight: 500;
          color: #17171b;
        }
        .docker-copy {
          margin-top: 25px;
          position: relative;
          text-align: right;
          line-height: 38px;
          padding-right: 22px;
          height: 38px;
          background: #474753;
          border-radius: 10px 10px 0px 0px;
          cursor: pointer;
          color: #ffffff;
        }
        .docker-copy::before {
          position: absolute;
          right: 44px;
          top: 0;
          content: "";
          width: 38px;
          height: 38px;
          background-image: url("../assets/v4/download16.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
        .docker-copy:hover {
          color: #00c186;
        }
        .docker-copy:hover::before {
          background-image: url("../assets/v4/download18.png");
        }
        .docker-copy-text {
          padding-left: 22px;
          height: 160px;
          color: #bbbbbb;
          background: #000000;
          font-size: 16px;
          overflow: hidden;
          border-radius: 0px 0px 10px 10px;
          .text1 {
            margin-top: 18px;
          }
          .text2,
          .text3,
          .text4,
          .text5 {
            margin-left: 22px;
          }
        }

        .download-btns {
          text-align: center;
          .download-btn {
            display: inline-block;
            vertical-align: top;
          }
        }

        .download-btn {
          display: block;
          margin: 0 10px;
          margin-top: 50px;
          text-decoration: none;
          color: #ffffff;
          font-size: 16px;
          line-height: 44px;
          width: 130px;
          height: 44px;
          background: #3772FF;
          border-radius: 6px;
          text-align: center;
          cursor: pointer;
          box-sizing: border-box;
        }
        .download-btn:hover {
          opacity: 0.9;
        }
        .download-btn1 {
          border: 1px solid #3772FF;
          background-color: transparent;
          color: #3772FF;
        }
        .download-btn1:hover {
          background: #3772FF;
          color: #ffffff;
          opacity: 0.9;
        }
      }
      .right {
        margin-left: 30px;
      }
      .lr:after {
        content: "";
        display: block; /* 设置添加子元素为块级元素 */
        height: 0; /* 设置添加的子元素的高度0 */
        visibility: hidden; /* 设置添加子元素看不见 */
        clear: both; /* 设置clear：both */
      }

      .t2-c {
        margin-top: 44px;
        margin-left: 62px;
        line-height: 22px;
        color: #57576c;
        font-size: 14px;
        .t2a1 {
          position: relative;
          padding-left: 20px;
        }
        .t2a1:before {
          position: absolute;
          left: 10px;
          top: 10px;
          content: "";
          width: 2px;
          height: 2px;
          border-radius: 50%;
          background-color: #57576c;
        }
      }
    }
    .openwrt-item {
      margin: 0 auto;
      margin-top: 70px;
      margin-bottom: 120px;
      width: 1280px;
      height: 760px;
      background: #f6f8ff;
      border: 1px solid #d9e1ff;
      border-radius: 16px;
      text-align: center;
      overflow: hidden;

      .t1-title {
        margin-top: 40px;
        font-size: 36px;
        font-weight: bold;
        color: #17171b;
        text-align: center;
        line-height: 52px;
      }

      .openwrt1 {
        display: inline-block;
        margin-top: 42px;
        margin-right: 100px;

        .t1 {
          color: #17171b;
          font-size: 24px;
          font-weight: 500;
          line-height: 35px;
          width: 200px;
          text-align: center;
        }
        .download-btn {
          display: block;
          margin-top: 26px;
          text-decoration: none;
          width: 200px;
          height: 44px;
          line-height: 44px;
          border-radius: 6px;
          background: #3772FF;
          font-size: 16px;
          color: #ffffff;
          text-align: center;
        }
        .download-btn:hover {
          opacity: 0.9;
        }
      }
      .openwrt1:nth-child(5) {
        margin-right: 0;
      }
      .t-install-c {
        margin: 0 auto;
        margin-top: 54px;
        width: 1104px;
        .t-install {
          font-size: 20px;
          font-weight: 500;
          line-height: 28px;
          text-align: left;
        }
        .docker-copy {
          margin-top: 25px;
          position: relative;
          text-align: right;
          line-height: 38px;
          padding-right: 22px;
          height: 38px;
          background: #474753;
          border-radius: 10px 10px 0px 0px;
          cursor: pointer;
          color: #ffffff;
          .shell-command {
            position: absolute;
            top: 0;
            left: 22px;
            color: #ffffff;
            font-size: 18px;
            line-height: 38px;
          }
        }
        .docker-copy::before {
          position: absolute;
          right: 44px;
          top: 0;
          content: "";
          width: 38px;
          height: 38px;
          background-image: url("../assets/v4/download16.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
        .docker-copy:hover {
          color: #00c186;
        }
        .docker-copy:hover::before {
          background-image: url("../assets/v4/download18.png");
        }

        .docker-copy-text {
          padding-left: 22px;
          height: 60px;
          line-height: 60px;
          color: #bbbbbb;
          background: #000000;
          font-size: 16px;
          overflow: hidden;
          border-radius: 0px 0px 10px 10px;
          text-align: left;
        }
      }
    }
  }
  .download-wrap {
    padding-top: 1px;
    width: 100%;
    height: 824px;
    background-image: url("../assets/v2/down1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .check-btns {
      margin: 0 auto;
      margin-top: 92px;
      width: 400px;
      text-align: center;
      height: 68px;
      .btn {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        height: 68px;
        line-height: 68px;
        font-size: 24px;
        color: #1a1a1a;
        box-sizing: border-box;
        cursor: pointer;
      }
      .btn-left {
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
      }
      .btn-right {
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
      }
      .active-btn {
        font-weight: 600;
        color: #ffffff;
        background-color: #133875;
      }
      .unactive-left {
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-right: none;
      }
      .unactive-right {
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-left: none;
      }
    }
    .platform-wrap {
      margin-top: 24px;
      text-align: center;
      .item {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 190px;
        height: 63px;
        box-sizing: border-box;
        cursor: pointer;
        .right-top {
          position: absolute;
          top: -15px;
          right: 10px;
          font-size: 12px;
          height: 22px;
          line-height: 22px;
          border-top-left-radius: 5px;
          border-bottom-right-radius: 5px;
          padding: 0 6px;
          background-image: linear-gradient(
            to bottom,
            rgba(118, 24, 219, 1),
            rgba(40, 24, 219, 1)
          );
          color: #ffffff;
        }
        .item-icon {
          display: inline-block;
          margin-top: 9px;
          margin-right: 8px;
          vertical-align: top;
          width: 28px;
          height: 28px;
          background-image: url("../assets/v2/down_win.svg");
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
        .item-text {
          display: inline-block;
          margin-top: 10px;
          vertical-align: top;
          font-weight: 700;
          font-size: 20px;
          line-height: 26px;
          text-align: center;
          color: #8b8f9f;
        }

        .item-icon2 {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 0);
          width: 86px;
          height: 2px;
          background-color: #1c407a;
          border-radius: 20px;
        }
      }
      .item2 {
        .item-icon {
          background-image: url("../assets/v2/down_mac.svg");
        }
      }
      .item3 {
        .item-icon {
          background-image: url("../assets/v2/down_android.svg");
        }
      }
      .item4 {
        .item-icon {
          background-image: url("../assets/v2/down_ios.svg");
        }
      }
      .item5 {
        .item-icon {
          background-image: url("../assets/v2/down_pc.svg");
        }
      }
      .item6 {
        .item-icon {
          background-image: url("../assets/v2/down_nas.svg");
        }
      }
      .active-item {
        .item-text {
          color: #1c407a;
        }
        .item-icon {
          background-image: url("../assets/v2/down_win2.svg");
        }
        .item-iconb {
          background-image: url("../assets/v2/down_mac2.svg");
        }
        .item-iconc {
          background-image: url("../assets/v2/down_android2.svg");
        }
        .item-icond {
          background-image: url("../assets/v2/down_ios2.svg");
        }
        .item-icone {
          background-image: url("../assets/v2/down_pc2.svg");
        }
        .item-iconf {
          background-image: url("../assets/v2/down_nas2.svg");
        }
      }
    }
    .platform1 {
      margin-top: 40px;
      .left {
        display: inline-block;
        vertical-align: top;
        margin-left: 484px;
        .text1 {
          font-weight: 700;
          font-size: 32px;
          line-height: 64px;
          color: #282938;
        }
        .item-wrap {
          margin-top: 58px;
          .item {
            display: inline-block;
            vertical-align: top;
            text-align: left;
            width: 148px;
            .item-icon,
            .item-text {
              display: inline-block;
              vertical-align: top;
            }
            .item-icon {
              margin-left: 16px;
              width: 24px;
              height: 24px;
              background-image: url("../assets/v2/down2.png");
              background-repeat: no-repeat;
              background-size: 100% 100%;
            }
            .item-icon2 {
              background-image: url("../assets/v2/down3.png");
            }
            .item-icon3 {
              background-image: url("../assets/v2/down4.png");
            }
            .item-icon4 {
              background-image: url("../assets/v2/down5.png");
            }
            .item-icon5 {
              background-image: url("../assets/v2/down6.png");
            }
            .item-icon6 {
              background-image: url("../assets/v2/down7.png");
            }
            .item-icon7 {
              background-image: url("../assets/v2/down11.png");
            }
            .item-icon8 {
              background-image: url("../assets/v2/down12.png");
            }
            .item-icon9 {
              background-image: url("../assets/v2/down13.png");
            }
            .item-icon10 {
              background-image: url("../assets/v2/down14.png");
            }
            .item-text {
              margin-left: 8px;
              line-height: 24px;
              font-size: 16px;
            }
          }
        }
        .item-wrap2 {
          margin-top: 20px;
        }
        .download-btn {
          margin-top: 84px;
          display: inline-block;
          width: 148px;
          height: 40px;
          line-height: 40px;
          text-decoration: none;
          background-color: #133875;
          border-radius: 4px;
          text-align: center;
          font-weight: 700;
          font-size: 16px;
          color: #ffffff;
          .download-icon {
            display: inline-block;
            margin-top: 11px;
            margin-right: 8px;
            vertical-align: top;
            width: 16px;
            height: 16px;
            background-image: url("../assets/v2/down8.svg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
        }
        .text2 {
          font-size: 14px;
          line-height: 28px;
          color: rgba(0, 0, 0, 0.4);
        }
      }
      .right {
        display: inline-block;
        margin-top: 10px;
        margin-left: 24px;
        vertical-align: top;
        width: 480px;
        height: 300px;
        background-image: url("../assets/v2/down9.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
    .platform3 {
      .right {
        margin-left: 60px;
        width: 417px;
        height: 325px;
        background-image: url("../assets/v2/down10.png");
      }
    }
    .platform5 {
      .right {
        margin-left: 30px;
        width: 500px;
        height: 318px;
        background-image: url("../assets/v2/down17.png");
      }
    }
    .platform6 {
      margin: 0 auto;
      margin-top: 10px;
      height: 540px;
      width: 1180px;
      box-sizing: border-box;
      background-image: url("../assets/download_img3.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;

      .part-wrap {
        margin-top: 20px;
        display: inline-block;
        vertical-align: top;
        .text1 {
          margin-left: 36px;
          font-weight: 700;
          font-size: 32px;
          line-height: 64px;
          color: #282938;
        }
        .text2 {
          margin-left: 36px;
          margin-top: 22px;
          margin-bottom: 40px;
          line-height: 24px;
          width: 260px;
          color: #666666;
          font-size: 18px;
        }
        .parterner {
          position: relative;
          width: 330px;
          height: 64px;
          cursor: pointer;

          .parterner-logo-wrap {
            margin-left: 45px;
            padding-top: 1px;
            width: 127px;
            height: 100%;
            box-sizing: border-box;
            .parterner-logo1 {
              margin: 0 auto;
              margin-top: 18px;
              width: 110px;
              height: 28px;
              background-image: url("../assets/download104.svg");
              .mixinBg();
            }
            .parterner-logo2 {
              margin: 0 auto;
              margin-top: 12px;
              width: 84px;
              height: 40px;
              background-image: url("../assets/download106.svg");
              .mixinBg();
            }
            .parterner-logo3 {
              margin: 0 auto;
              margin-top: 24px;
              width: 123px;
              height: 16px;
              background-image: url("../assets/download107.svg");
              .mixinBg();
            }
            .parterner-logo4 {
              margin: 0 auto;
              margin-top: 22px;
              width: 128px;
              height: 20px;
              background-image: url("../assets/download108.svg");
              .mixinBg();
            }
            .parterner-logo5 {
              margin: 0 auto;
              margin-top: 19px;
              width: 115px;
              height: 23px;
              background-image: url("../assets/download109.svg");
              .mixinBg();
            }
          }

          .parterner-text {
            position: absolute;
            left: 192px;
            top: 50%;
            transform: translate(0, -50%);
            font-size: 20px;
            color: #191919;
          }
        }
        .parterner-bg {
          background-image: url("../assets/download105.png");
          .mixinBg();
          font-weight: 700;
        }
      }

      .download-wrap {
        display: inline-block;
        margin-top: 40px;
        vertical-align: top;
        width: 818px;
        height: 460px;
        background-image: url("../assets/download110.png");
        .mixinBg();
        box-shadow: 0 4px 8px 0 rgba(10, 24, 61, 32%);
        border-radius: 16px;
        text-align: center;

        .logo {
          margin: 0 auto;
          margin-top: 64px;
          width: 212px;
          height: 55px;
          background-image: url("../assets/download111.svg");
          .mixinBg();
        }

        .download-btn-wrap {
          padding-top: 24px;
          margin: 0 auto;
          margin-top: 60px;
          width: 730px;
          height: 174px;
          border-radius: 16px;
          background-color: rgba(4, 4, 22, 0.8);
          text-align: center;
          box-sizing: border-box;

          .download-btn {
            position: relative;
            display: inline-block;
            padding-top: 1px;
            vertical-align: top;
            width: 173px;
            height: 128px;
            border-radius: 16px;
            box-sizing: border-box;
            cursor: pointer;

            .texta {
              margin-top: 35px;
              margin-top: 10px;
              color: #ffffff;
              font-size: 20px;
              text-align: center;
              font-weight: 700;
            }
            .textb {
              margin-top: 10px;
            }
            .textc {
              margin-top: 8px;
              font-size: 16px;
              color: #ffffff;
              text-align: center;
            }
            .btn {
              position: relative;
              display: block;
              margin: 0 auto;
              margin-top: 12px;
              padding-left: 71px;
              text-align: left;
              width: 146px;
              height: 36px;
              line-height: 36px;
              border-radius: 4px;
              background-color: #2749c1;
              font-size: 16px;
              font-weight: 700;
              color: #ffffff;
              box-sizing: border-box;
              text-decoration: none;
            }
            .btn::before {
              position: absolute;
              left: 43px;
              top: 7px;
              content: "";
              width: 20px;
              height: 20px;
              background-image: url("../assets/download112.svg");
              .mixinBg();
            }
          }
          .download-btn:nth-child(-n + 3)::after {
            position: absolute;
            right: 0;
            top: 16px;
            content: "";
            height: 78px;
            width: 1px;
            background-image: url("../assets/download114.png");
            .mixinBg();
          }
          .download-btn-bg {
            background-image: url("../assets/download113.png");
            .mixinBg();
          }
        }

        .answer {
          position: relative;
          margin-top: 20px;
          margin-right: 16px;
          display: inline-block;
          text-decoration: none;
          color: #2749c1;
          font-size: 16px;
          cursor: pointer;
          font-weight: 700;
          width: 116px;
          height: 28px;
          line-height: 28px;
          background-color: rgba(255, 255, 255, 0.9);
          border-radius: 4px;
        }
        .getHelp {
          display: inline-block;
          margin-top: 20px;
          line-height: 28px;
          font-size: 14px;
          color: #ffffff;
          text-decoration: none;
        }
      }
      .download-wrap2 {
        display: inline-block;
        margin-top: 40px;
        vertical-align: top;
        width: 818px;
        height: 460px;
        background-image: url("../assets/download115.png");
        .mixinBg();
        box-shadow: 0 4px 8px 0 rgba(10, 24, 61, 32%);
        border-radius: 16px;
        text-align: center;

        .logo {
          margin: 0 auto;
          margin-top: 60px;
          width: 192px;
          height: 93px;
          background-image: url("../assets/download116.svg");
          .mixinBg();
        }

        .answer {
          position: relative;
          margin-top: 20px;
          margin-right: 16px;
          display: inline-block;
          text-decoration: none;
          color: #2749c1;
          font-size: 16px;
          cursor: pointer;
          font-weight: 700;
          width: 116px;
          height: 28px;
          line-height: 28px;
          background-color: rgba(255, 255, 255, 0.9);
          border-radius: 4px;
        }
        .getHelp {
          display: inline-block;
          margin-top: 20px;
          height: 28px;
          font-size: 14px;
          color: #ffffff;
          text-decoration: none;
        }

        .download-btn-wrap {
          padding-top: 24px;
          margin: 0 auto;
          margin-top: 40px;
          width: 730px;
          height: 174px;
          border-radius: 16px;
          background-color: rgba(4, 4, 22, 0.8);
          text-align: center;
          box-sizing: border-box;

          .download-btn {
            position: relative;
            display: inline-block;
            padding-top: 1px;
            vertical-align: top;
            width: 173px;
            height: 128px;
            border-radius: 16px;
            box-sizing: border-box;
            cursor: pointer;

            .texta {
              margin-top: 35px;
              margin-top: 10px;
              color: #ffffff;
              font-size: 20px;
              text-align: center;
              font-weight: 700;
            }
            .textb {
              margin-top: 10px;
            }
            .textc {
              margin-top: 8px;
              font-size: 16px;
              color: #ffffff;
              text-align: center;
            }
            .btn {
              position: relative;
              display: block;
              margin: 0 auto;
              margin-top: 12px;
              padding-left: 71px;
              text-align: left;
              width: 146px;
              height: 36px;
              line-height: 36px;
              border-radius: 4px;
              background-color: #2749c1;
              font-size: 16px;
              font-weight: 700;
              color: #ffffff;
              box-sizing: border-box;
              text-decoration: none;
            }
            .btn::before {
              position: absolute;
              left: 43px;
              top: 7px;
              content: "";
              width: 20px;
              height: 20px;
              background-image: url("../assets/download112.svg");
              .mixinBg();
            }
          }
          .download-btn:nth-child(-n + 3)::after {
            position: absolute;
            right: 0;
            top: 16px;
            content: "";
            height: 78px;
            width: 1px;
            background-image: url("../assets/download114.png");
            .mixinBg();
          }
          .download-btn-bg {
            background-image: url("../assets/download113.png");
            .mixinBg();
          }
        }
      }
      .download-wrap3 {
        display: inline-block;
        margin-top: 40px;
        vertical-align: top;
        width: 818px;
        height: 460px;
        background-image: url("../assets/download116.png");
        .mixinBg();
        box-shadow: 0 4px 8px 0 rgba(10, 24, 61, 32%);
        border-radius: 16px;

        .logo {
          margin: 0 auto;
          margin-top: 90px;
          width: 264px;
          height: 34px;
          background-image: url("../assets/download117.svg");
          .mixinBg();
        }

        .getHelp {
          margin: 0 auto;
          display: block;
          width: 200px;
          line-height: 30px;
          margin-top: 15px;
          font-size: 14px;
          color: #ffffff;
          text-decoration: none;
          text-align: center;
        }
        .download-btn-wrap {
          padding-top: 42px;
          margin: 0 auto;
          margin-top: 40px;
          width: 402px;
          height: 204px;
          border-radius: 2px;
          background-image: url("../assets/download113.png");
          .mixinBg();
          box-sizing: border-box;

          .texta {
            font-size: 20px;
            color: #ffffff;
            font-weight: 700;
            text-align: center;
          }
          .textb {
            margin-top: 8px;
            font-size: 16px;
            color: #ffffff;
            text-align: center;
          }
          .btn {
            position: relative;
            margin: 0 auto;
            margin-top: 35px;
            width: 209px;
            height: 42px;
            line-height: 42px;
            background-color: #2749c1;
            border-radius: 4px;
            color: #ffffff;
            box-sizing: border-box;
            font-size: 16px;
            font-weight: 700;
            cursor: pointer;
            text-align: center;
          }
          .btn:hover {
            cursor: pointer;
            opacity: 0.9;
          }
        }
      }
      .download-wrap4 {
        display: inline-block;
        margin-top: 40px;
        vertical-align: top;
        width: 818px;
        height: 460px;
        background-image: url("../assets/download118.png");
        .mixinBg();
        box-shadow: 0 4px 8px 0 rgba(10, 24, 61, 32%);
        border-radius: 16px;
        text-align: center;

        .logo {
          margin: 0 auto;
          margin-top: 90px;
          width: 231px;
          height: 37px;
          background-image: url("../assets/download119.svg");
          .mixinBg();
        }

        .answer {
          position: relative;
          margin-top: 20px;
          margin-right: 16px;
          display: inline-block;
          text-decoration: none;
          color: #2749c1;
          font-size: 16px;
          cursor: pointer;
          font-weight: 700;
          width: 116px;
          height: 28px;
          line-height: 28px;
          background-color: rgba(255, 255, 255, 0.9);
          border-radius: 4px;
        }
        .getHelp {
          display: inline-block;
          margin-top: 20px;
          font-size: 14px;
          color: #ffffff;
          line-height: 28px;
          text-decoration: none;
        }

        .download-btn-wrap {
          display: inline-block;
          padding-top: 42px;
          margin: 0 auto;
          margin-top: 40px;
          width: 302px;
          height: 204px;
          border-radius: 2px;
          background-image: url("../assets/download113.png");
          .mixinBg();
          box-sizing: border-box;

          .texta {
            font-size: 20px;
            color: #ffffff;
            font-weight: 700;
            text-align: center;
          }
          .textb {
            margin-top: 8px;
            font-size: 16px;
            color: #ffffff;
            text-align: center;
          }
          .btn {
            text-align: left;
            position: relative;
            display: block;
            padding-left: 103px;
            margin: 0 auto;
            margin-top: 35px;
            width: 209px;
            height: 42px;
            line-height: 42px;
            background-color: #2749c1;
            border-radius: 4px;
            color: #ffffff;
            box-sizing: border-box;
            font-size: 16px;
            font-weight: 700;
            cursor: pointer;
            text-decoration: none;
          }
          .btn::before {
            position: absolute;
            left: 75px;
            top: 11px;
            content: "";
            width: 20px;
            height: 20px;
            background-image: url("../assets/download112.svg");
            .mixinBg();
          }
          .btn:hover {
            cursor: pointer;
            opacity: 0.9;
          }
        }
        /*	.download-btn-wrap {
					padding-top: 42px;
					margin: 0 auto;
					margin-top: 58px;
					width: 402px;
					height: 204px;
					border-radius: 2px;
					background-image: url("../assets/download113.png");
					.mixinBg();
					box-sizing: border-box;

					.texta {
						font-size: 20px;
						color: #ffffff;
						font-weight: 700;
						text-align: center;
					}
					.textb {
						margin-top: 8px;
						font-size: 16px;
						color: #ffffff;
						text-align: center;
					}
					.btn {
						position: relative;
						display: block;
						padding-left: 103px;
						margin: 0 auto;
						margin-top: 35px;
						width: 209px;
						height: 42px;
						line-height: 42px;
						background-color: #2749c1;
						border-radius: 4px;
						color: #ffffff;
						box-sizing: border-box;
						font-size: 16px;
						font-weight: 700;
						cursor: pointer;
						text-decoration: none;
					}
					.btn::before {
						position: absolute;
						left: 75px;
						top: 11px;
						content: "";
						width: 20px;
						height: 20px;
						background-image: url("../assets/download112.svg");
						.mixinBg();
					}
					.btn:hover {
						cursor: pointer;
						opacity: 0.9;
					}
				}*/
      }
      .download-wrap5 {
        display: inline-block;
        margin-top: 40px;
        vertical-align: top;
        width: 818px;
        height: 460px;
        background-image: url("../assets/download120.png");
        .mixinBg();
        box-shadow: 0 4px 8px 0 rgba(10, 24, 61, 32%);
        border-radius: 16px;

        .logo {
          margin: 0 auto;
          margin-top: 90px;
          width: 221px;
          height: 44px;
          background-image: url("../assets/download121.svg");
          .mixinBg();
        }
        .getHelp {
          margin: 0 auto;
          display: block;
          width: 200px;
          line-height: 30px;
          margin-top: 15px;
          font-size: 14px;
          color: #ffffff;
          text-decoration: none;
          text-align: center;
        }

        .download-btn-wrap {
          padding-top: 42px;
          margin: 0 auto;
          margin-top: 35px;
          width: 402px;
          height: 204px;
          border-radius: 2px;
          background-image: url("../assets/download113.png");
          .mixinBg();
          box-sizing: border-box;

          .texta {
            font-size: 20px;
            color: #ffffff;
            font-weight: 700;
            text-align: center;
          }
          .textb {
            margin-top: 8px;
            font-size: 16px;
            color: #ffffff;
            text-align: center;
          }
          .btn {
            position: relative;
            margin: 0 auto;
            margin-top: 35px;
            width: 209px;
            height: 42px;
            line-height: 42px;
            background-color: #2749c1;
            border-radius: 4px;
            color: #ffffff;
            box-sizing: border-box;
            font-size: 16px;
            font-weight: 700;
            cursor: pointer;
            text-align: center;
          }
          .btn:hover {
            cursor: pointer;
            opacity: 0.9;
          }
        }
      }
    }
    .platform7 {
      .item {
        width: 132px !important;
      }
      .right {
        margin-left: 90px;
        width: 358px;
        height: 318px;
        background-image: url("../assets/v2/down15.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .right8 {
        margin-left: 100px;
        width: 340px;
        height: 242px;
        background-image: url("../assets/v2/down16.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>