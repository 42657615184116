<template>
  <div id="nav" class="nav bg1">
    <div class="nav-main">
<!--      <router-link to="/">-->
<!--        <img :src="logo" alt="#" class="logo" />-->
<!--      </router-link>-->
<!--      <router-link to="/">-->
<!--        首页-->
<!--      </router-link>-->
<!--      <router-link to="/download">-->
<!--        产品-->
<!--      </router-link>-->

      <a @click="goClick('/','首页')" href="javascript:;" :class="{'router-link-exact-active':this.$route.path=='/'}">
        <img :src="logo" alt="#" class="logo" />
      </a>
      <a @click="goClick('/','首页')" href="javascript:;" :class="{'router-link-exact-active':this.$route.path=='/'}">
        首页
      </a>
      <a @click="goClick('/download','下载')" href="javascript:;" :class="{'router-link-exact-active':this.$route.path=='/download'}">
        下载
      </a>
      <a @click="goHelp" href="javascript:;">
        文档
      </a>

<!--      <router-link to="/about">关于我们</router-link>-->
<!--      <a class="download" @click="goHelp" href="javascript:;">帮助中心</a>-->
<!--      <a class="download" @click="goNews" href="javascript:;"> 资讯 </a>-->
<!--      <router-link to="/download"> 下载 </router-link>-->
<!--      <router-link to="/business" class="second-nav"> 购买 </router-link>-->
<!--      <router-link to="/" class="second-nav"> 首页 </router-link>-->

        <a class="download-btn" @click="goClick('https://iepose.com/download','下载')" target="_blank">
            开始使用
        </a>
        <a class="console-btn" @click="goClick('https://yc.iepose.com/jdxb_console/','小宝控制台')" target="_blank">
            控制台
        </a>
        <a class="console-btn" @click="goClick('https://yc.iepose.com/task','工单')" target="_blank">
            工单
        </a>

    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Nav",
  data: function () {
    return {
      logo: require("../assets/v5/logo.svg"),
      bg: "",
    };
  },
  props: {},
  methods: {
    ...mapActions([
      "sendLog"
    ]),
    goDownload:function(data){
        if(this.$route.path != data){
            this.$router.push(data);
        }
        this.sendLog(["PC_nav_clicked","开始使用"]);
    },
    goClick:function(data,type){
      if(data.includes("//")) {
        window.open(this.$store.getters.rebuildUrl(data));
      }else if(this.$route.path != data){
            this.$router.push(data);
            this.sendLog(["PC_nav_clicked",type]);
        }
    },
    goHelp: function () {
      this.sendLog(["PC_nav_clicked","文档"]);
      window.location.href = "/helpcenter_v3/";
    },
    goNews: function () {
      window.location.href = "/news";
    },
  },
  mounted() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.nav {
  position: fixed;
  top: 0;
  height: 70px;
  width: 100%;
  z-index: 9;
  border-bottom: 1px solid #e8eaf3;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(15px);

  .nav-main {
    margin: 0 auto;
    width: 1280px;
  }
  .nav-main:after {
    content: "";
    display: block; /* 设置添加子元素为块级元素 */
    height: 0; /* 设置添加的子元素的高度0 */
    visibility: hidden; /* 设置添加子元素看不见 */
    clear: both; /* 设置clear：both */
  }

  a {
    float: left;
    padding-left: 26px;
    padding-right: 26px;
    font-size: 16px;
    color: #1D1F24;
    text-decoration: none;
    height: 70px;
    line-height: 70px;
    vertical-align: top;
    cursor: pointer;
  }
  a:hover {
    color: #3772FF;
  }
  a.router-link-exact-active {
    color: #3772FF;
  }





  a:first-child {
    padding: 0;
    margin-right: 20px;
    .logo {
      margin-top: 18px;
      width: 140px;
      height: 32px;
    }
  }


  .download-btn{
    float: right;
    margin-left: 32px;
    margin-top: 15px;
    width: 80px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #3772FF;
    border-radius: 6px;
    font-size: 16px;
    color: #FFFFFF !important;
    font-weight: 500;
  }
  .download-btn:hover{
    color: #FFFFFF;
    opacity: 0.9;
  }
    .console-btn{
        float: right;
    }




}
.bg1 {
  background: rgba(255, 255, 255, 0.9);
}
</style>

